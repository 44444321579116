<template>
  <div class="sale-countdown-row" v-if="currentSaleCountdown && showTimerFlag">
    <div v-show="timerOutput" class="countdown-box">
      <div v-html="currentSaleCountdown" />
      <MProductAddToCart
        class="product__add-to-cart"
        :class="{ 'button-busy-green': isCartBusy }"
        :products="products"
        :stock="stock"
        :disabled="disabled"
        :is-out-of-stock="isOutOfStock"
        :caption="$t('BUY NOW')"
        :gifts="gifts"
      />
      <div class="sale-countdown" v-if="timerOutput">
        <div v-if="timerOutput.day" class="countdown-col countdown-day">
          {{ timerOutput.day }}
          <span>{{ $t('Day') }}</span>
        </div>
        <div class="countdown-col countdown-hours">
          {{ timerOutput.hours }}
          <span>{{ $t('Hours') }}</span>
        </div>
        <div class="countdown-col countdown-minutes">
          {{ timerOutput.minutes }}
          <span>{{ $t('Minutes') }}</span>
        </div>
        <div class="countdown-col countdown-seconds">
          {{ timerOutput.seconds }}
          <span>{{ $t('Seconds') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
const MProductAddToCart = () => import(/* webpackChunkName: "m-product-add-to-cart" */'~/theme/components/molecules/m-product-add-to-cart');

export default {
  name: 'MTickTacClock',
  components: {
    MProductAddToCart
  },
  props: {
    products: {
      type: Array,
      required: true,
      default: () => []
    },
    stock: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isOutOfStock: {
      type: Boolean,
      default: false
    },
    gifts: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      timerOutput: null,
      showTimerFlag: true
    }
  },
  computed: {
    currentSaleCountdown () {
      const block = this.cmsBlocks.find((_) => _.identifier === 'pdp_below_add_to_cart_block_timer') || {}
      return block.content || ''
    },
    ...mapGetters({
      cmsBlocks: 'cmsBlock/getCmsBlocks',
      isCartBusy: 'cart/getCartProcessingStatus'
    })
  },
  mounted () {
    var timer = setInterval(() => {
      if (this.showTimerFlag) {
        this.startTimer()
      } else {
        clearInterval(timer);
      }
    }, 1000);
  },
  methods: {
    startTimer () {
      const timeNow = Date.now();
      const timerDateElem = document.getElementById('timerDate');
      if (timerDateElem) {
        const targetTimeString = timerDateElem?.textContent?.trim();
        const [targetHours, targetMinutes] = targetTimeString.split(':').map(Number);
        let countDownToTime;
        if (isNaN(targetHours) || isNaN(targetMinutes) || targetHours < 0 || targetHours > 23 || targetMinutes < 0 || targetMinutes > 59) { // if time and date
          countDownToTime = new Date(timerDateElem?.textContent?.trim()).getTime();
        } else { // if time only
          countDownToTime = new Date();
          countDownToTime.setHours(targetHours, targetMinutes, 0, 0);
        }
        const timeDifference = countDownToTime - timeNow;
        const remainingDays = timeDifference / (1000 * 60 * 60 * 24);
        const remainingHours = (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60);
        const remainingMinutes = (timeDifference % (1000 * 60 * 60)) / (1000 * 60);
        const remainingSeconds = (timeDifference % (1000 * 60)) / 1000;
        this.timerOutput = timeDifference > 0 ? {
          day: Math.floor(remainingDays),
          hours: Math.floor(remainingHours),
          minutes: Math.floor(remainingMinutes),
          seconds: Math.floor(remainingSeconds)
        } : null;
        this.showTimerFlag = this.timerOutput !== null
      }
    }
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.sale-countdown-row {
  border: solid 2px #ddd;
  padding: 20px;
  border-radius: 5px;
  margin: 15px 0;
  text-align: center;

  .sale-countdown {
    display: flex;
    align-items: center;
    justify-content: center;

    .countdown-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      position: relative;
      padding: 0 15px;

      &::after {
        content: ':';
        position: absolute;
        right: -2px;
        top: 0;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      span {
        font-size: 12px;
        display: block;
        text-align: center;
        padding-top: 4px;
      }
    }
  }

  .countdown-box {
    position: relative;
    padding-bottom: 60px;
    #timerDate{
      display: block!important;
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      pointer-events: none ;
    }
  }

  .sf-button {
    padding: 10px;
    font-size: 14px;
    margin-top: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  h3 {
    margin: 0 0 20px 0;
  }
}

</style>
